import "bootstrap/dist/css/bootstrap.min.css";
import React, { Component, Suspense, lazy } from "react";
import { connect } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { login, logout } from "./actions";
import { httpGet } from "./actions/http";
import { closePopup } from "./actions/login";
import { setTokenTime } from "./actions/token";
import "./App.css";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import Spinner from "./components/Spinner/Spinner";

const Panel = lazy(() => import("./containers/Panel/Panel"));
const Login = lazy(() => import("./containers/Login/Login"));

class App extends Component {
  getConfig = async () => {
    try {
      const configResponse = await fetch("config.json");
      const configData = await configResponse?.json();
      localStorage.setItem("uri", configData?.backendUri);
      let timeRefresh = 200;

      if (configData && configData?.tokenRefreshTime) {
        timeRefresh = configData?.tokenRefreshTime;
      }

      this.props.setTokenTimeRef(timeRefresh);
    } catch (err) {
      throw err;
    }
  };

  componentDidMount = async () => {
    try {
      this.getConfig();
    } catch (err) {
      throw err;
    }
  };

  render() {
    return (
      <BrowserRouter>
        <Suspense fallback={<Spinner show={true} />}>
          <Switch>
            <Route
              exact
              path="/"
              render={props => (
                <Login
                  {...props}
                  login={this.props.login}
                  loginStatus={this.props.loginStatus}
                  auth={this.props.auth}
                  timeExpired={this.props.timeExpired}
                  closePopup={this.props.closePopup}
                  spinner={this.props.spinner}
                  httpGet={this.props.httpGet}
                />
              )}
            />
            <ProtectedRoute
              path="/panel"
              render={props => <Panel {...props} logout={this.props.logout} spinner={this.props.spinner} />}
            />
          </Switch>
        </Suspense>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  spinner: state.spinner,
  loginStatus: state.loginStatus,
  timeExpired: state.timeExpired,
});

const mapDispatchToProps = dispatch => ({
  login: (username, password) => dispatch(login(username, password)),
  logout: () => dispatch(logout()),
  setTokenTimeRef: data => dispatch(setTokenTime(data)),
  httpGet: path => dispatch(httpGet(path)),
  closePopup: () => dispatch(closePopup()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
